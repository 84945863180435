import React from 'react';
import FormWithEmailInput from './FormWithEmailInput';

const NewsletterSignup = () => {
  return (
    <div className="lg:col-span-3 xl:col-span-1">
      <h3 className="text-17 mb-3 sm:text-xl sm:mb-6 lg:text-2xl">
        Newsletter abonnieren
      </h3>
      <p className="text-15 lg:text-base mb-6">
        Verpassen Sie keine spannenden Beiträge mehr!
      </p>
      <FormWithEmailInput
        url="https://hooks.zapier.com/hooks/catch/8845339/bmk0grk"
        wide
      />
      <p className="text-15 lg:text-base mt-6 mb-3">
        Sie wollen regelmäßig spannende Artikel rund um das Thema Nachfolge lesen? Dann melden Sie sich hier für unseren kostenlosen Newsletter und verpassen Sie keine Beiträge mehr. Es erwarten Sie unter anderem nützliche Tipps, Inspirationen, Anekdoten bzw. Erfolgsgeschichten, Erklärungen wichtiger Fachbegriffe sowie Studien und umfangreiche Marktberichte. Sie können den E-Mail-Newsletter jederzeit wieder abbestellen.
      </p>
    </div>
  );
};

export default NewsletterSignup;
