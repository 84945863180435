import React from 'react'
import PropTypes from 'prop-types'

import ContactForm from '../components/ContactForm'
import NewsletterSignup from '../components/NewsletterSignup'
import ContentHeaderBgImg from '../components/ContentHeaderBgImg'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = ({ pageContext }) => {
  const thumbnails = {
    thumbnailSmall: (pageContext.thumbnailSmall.length ? pageContext.thumbnailSmall[0].url : null),
    thumbnailMedium: (pageContext.thumbnailMedium.length ? pageContext.thumbnailMedium[0].url : null),
    thumbnailLarge: (pageContext.thumbnailLarge.length ? pageContext.thumbnailLarge[0].url : null)
  }

  return (
    <Layout>
      <SEO seomatic={pageContext.seomatic} />
      <ContentHeaderBgImg 
        title={pageContext.title} 
        description={pageContext.description} 
        meta={pageContext.excerpt} 
        thumbnails={thumbnails}
      />
      <section className="px-5 my-10 md:px-10 sm:my-15 lg:my-20">
        <div className="m-auto max-w-screen-xxl grid grid-cols-1 lg:grid-cols-5 lg:gap-24 xl:grid-cols-2">
          <div className="mt-15 lg:mt-0 lg:col-span-2 xl:col-span-1">
            {pageContext.contactBlock.map(entry => (
              <div key={entry.id} className="mb-10 md:mb-12 xl:mb-15">
                <h5 className="text-17 mb-3 sm:text-xl lg:text-2xl">
                  {entry.headline}
                </h5>
                {entry.text && <p className="text-15 mb-3">{entry.text}</p>}
                <div className="text-15 lg:text-base">
                  {entry.phoneNumber && (
                    <p>
                      <a
                        href={`tel:+${entry.phoneNumber}`}
                        className="link inline-block"
                      >
                        {entry.phoneNumber}
                      </a>
                    </p>
                  )}
                  {entry.email && (
                    <p>
                      <a
                        href={`mailto:${entry.email}`}
                        className="link inline-block"
                      >
                        {entry.email}
                      </a>
                    </p>
                  )}
                  {entry.address && <p>{entry.address}</p>}
                </div>
              </div>
            ))}
          </div>
          <NewsletterSignup />
        </div>
      </section>
    </Layout>
  )
}

Contact.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    contactBlock: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        email: PropTypes.string,
        headline: PropTypes.string,
        id: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string,
        text: PropTypes.string
      })
    )
  })
}

export default Contact
