import * as Yup from 'yup';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const contactFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Titel ist erforderlich')
    .oneOf(
      ['Herr', 'Frau', 'Divers'],
      'Die Eingabe ist ungültig. Es muss entweder Frau oder Herr sein'
    ),
  lastName: Yup.string()
    .min(2, 'Der Nachname muss mindestens 2 Zeichen lang sein')
    .max(50, 'Der Nachname muss weniger als 50 Zeichen enthalten')
    .required('Der Nachname ist erforderlich'),
  firstName: Yup.string()
    .min(2, 'Der Vorname muss mindestens 2 Zeichen lang sein')
    .max(50, 'Der Vorname muss weniger als 50 Zeichen enthalten')
    .required('Der Vorname ist erforderlich'),
  email: Yup.string()
    .email('Ungültige E-Mail-Adresse')
    .required('E-Mail-Adresse ist erforderlich'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Die Telefonnummer ist ungültig')
    .min(6, 'Die Telefonnummer muss mindestens 6 Zeichen lang sein')
    .max(25, 'Der Telefonnummer muss weniger als 25 Zeichen enthalten'),
  callBack: Yup.boolean().oneOf([true, false], 'Die Eingabe ist ungültig'),
  message: Yup.string()
    .min(10, 'Die Nachricht muss mindestens 10 Zeichen lang sein')
    .max(1000, 'Die Nachricht muss wineger als 1000 Zeichen enthalten')
    .nullable(),
  terms: Yup.boolean().oneOf(
    [true],
    'Sie müssen den Datenschutzbestimmungen zustimmen'
  ),
});
